import { isNud } from '@/shared/utilities/typing';

/**
 * ヤフオク 出品中情報 エンティティ
 */
class YahooAuctionInformation {
    title;
    current;
    buynow;
    start;
    end;
    period;
    image;
    url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.title = properties.title;
        this.current = properties.current;
        this.buynow = properties.buynow;
        this.start = properties.start;
        this.end = properties.end;
        this.period = properties.period;
        this.image = properties.image;
        this.url = properties.url;
    }

    get buynow_display() {
        if (!this.buynow) {
            return '-';
        }

        return this.buynow;
    }

    get current_display() {
        if (!this.current) {
            return '-';
        }

        // numberFormat() はやめて、スクレイピングした結果そのままを返す
        return this.current;
    }
}

export default YahooAuctionInformation;
