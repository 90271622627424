import Enum from './enum'

/**
 * Amazonへの同期タイプ
 *
 * 1:登録
 * 2:更新
 * 3:削除
 */
class SyncQueueType extends Enum {
    static REGISTER = 1;
    static UPDATE = 2;
    static DELETE = 3;

    static values() {
        return {
            [this.REGISTER]: '登録',
            [this.UPDATE]: '更新',
            [this.DELETE]: '削除',
        }
    }
}

export default SyncQueueType;
