import Enum from './enum'

/**
 * Amazon同期ステータス
 *
 * 1:同期待ち
 * 2:同期中
 * 3:同期済
 * 9:同期エラー
 * 19:SKUレコードが存在しない為、同期不可
 */
class SyncQueueStatus extends Enum {
    static WAITING = 1;
    static SYNCING = 2;
    static DONE = 3;
    static ERROR = 9;
    static ERROR_SKU_NOT_FOUND = 19;

    static values() {
        return {
            [this.WAITING]: 'Amazon同期待ち',
            [this.SYNCING]: 'Amazon同期中',
            [this.DONE]: 'Amazon同期完了',
            [this.ERROR]: 'Amazon同期エラー',
            [this.ERROR_SKU_NOT_FOUND]: 'Amazon同期不可 (SKUが存在しない)',
        }
    }
}

export default SyncQueueStatus;
