<template>
    <section class="section" v-if="!asin || !auction_url">
        <div class="alert alert-warning">ASIN・ヤフオクURLが指定されていません</div>
        <form class="row align-items-end" @submit.prevent="prepare">
            <div class="col-md-6">
                <label class="form-label">ASIN</label>
                <input type="text" v-model="prepare_form.asin" class="form-control" required>
            </div>
            <div class="col-md-12">
                <label class="form-label">ヤフオクURL</label>
                <input type="text" v-model="prepare_form.auction_url" class="form-control" required>
            </div>
            <div class="col-md-6">
                <button class="btn btn-info">情報取得</button>
            </div>
        </form>
    </section>

    <template v-else>
        <section class="section">
            <h4 class="mb-2">Amazon商品情報</h4>
            <template v-if="asin_info_loading">
                <inline-loader message="Amazonから商品情報を取得中..."></inline-loader>
            </template>
            <template v-else-if="asin_info === null">
                <div class="alert alert-danger">商品情報の取得に失敗しました</div>
            </template>
            <template v-else>
                <div class="row">
                    <div class="col-md-4">
                        <img :src="asin_info.img" v-if="asin_info.img" class="w-100">
                        <div class="alert alert-secondary" v-else>NO IMAGE</div>
                    </div>
                    <div class="col-md-12">
                        <h5>{{ asin_info.item_name }}</h5>
                        <div>ASIN: <span>{{ asin_info.asin }}</span></div>
                        <div>サイズ: <span>{{ asin_info.width }}×{{ asin_info.height }}×{{ asin_info.length }} cm</span></div>
                        <div>重量: <span>{{ asin_info.weight }} {{ asin_info.weight_units }}</span></div>
                    </div>
                    <div class="col-md-8">
                        <template v-if="asin_offers_loading">
                            <inline-loader message="Amazonから競合出品情報を取得中..."></inline-loader>
                        </template>
                        <div v-else-if="asin_offers.length === 0" class="alert alert-secondary">
                            競合の出品がありません
                        </div>
                        <table v-else class="table text-center">
                            <thead>
                                <tr>
                                    <th>競合販売価格</th>
                                    <th>コンディション</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="offer,index of asin_offers" :key="index">
                                    <td>{{ $helper.number(offer.price) }}</td>
                                    <td>{{ SubCondition.display(offer.sub_condition) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </section>

        <section class="section">
            <h4 class="mb-2">ヤフオク情報</h4>
            <template v-if="auction_info_loading">
                <inline-loader message="ヤフオクからオークション情報を取得中..."></inline-loader>
            </template>
            <template v-else-if="auction_info === null">
                <div class="alert alert-danger">オークション情報の取得に失敗しました</div>
            </template>
            <template v-else>
                <div class="row">
                    <div class="col-md-4">
                        <img :src="auction_info.image" v-if="auction_info.image" class="w-100">
                        <div class="alert alert-secondary" v-else>NO IMAGE</div>
                    </div>
                    <div class="col-md-20">
                        <h5>{{ auction_info.title }}</h5>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>開始日時</th>
                                    <th>終了日時</th>
                                    <th>現在価格</th>
                                    <th>即決価格</th>
                                    <th>残り時間</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ auction_info.start }}</td>
                                    <td>{{ auction_info.end }}</td>
                                    <td>{{ auction_info.current_display }}</td>
                                    <td>{{ auction_info.buynow_display }}</td>
                                    <td>{{ auction_info.period }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>販売価格</th>
                                    <th>予想落札価格</th>
                                    <th>Amazon手数料</th>
                                    <th>送料</th>
                                    <th>その他手数料</th>
                                    <th>利益額</th>
                                    <th>利益率</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ $helper.number(calculator.listing_price) }}</td>
                                    <td>
                                        <input
                                            type="number"
                                            class="form-control no-spin"
                                            v-model="calculator.estimated_cost"
                                            @change="calculate"
                                        >
                                    </td>
                                    <td>
                                        {{ $helper.number(calculator.referral_fee) }}
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            class="form-control no-spin"
                                            v-model="calculator.estimated_shipping"
                                            @change="calculate"
                                        >
                                    </td>
                                    <td>
                                        {{ $helper.number(calculator.other_fee) }}
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            class="form-control no-spin"
                                            :class="{'border-primary': calculator.base === 'profit_price'}" v-model="calculator.profit_price" @change="calculate('profit_price')"
                                        >
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            class="form-control"
                                            :class="{'border-primary': calculator.base === 'profit_ratio'}"
                                            v-model="calculator.profit_ratio"
                                            @change="calculate('profit_ratio')"
                                            step="0.1"
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <template v-if="keyword">
                    <h5><b>落札履歴</b> <small>(キーワード : {{ keyword }}) <a :href="history_url" target="_blank">ヤフオクで見る</a></small></h5>

                    <template v-if="auction_history_loading">
                        <inline-loader message="ヤフオクから落札履歴を取得中..."></inline-loader>
                    </template>
                    <div v-else-if="auction_history.length === 0" class="alert alert-secondary">
                        落札履歴がありません
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>オークション名</th>
                                <th>落札価格</th>
                                <th>落札日時</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row,index of auction_history" :key="index">
                                <td>{{ row.title }}</td>
                                <td>{{ $helper.number(row.price) }}</td>
                                <td>{{ row.end_time }}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </template>
        </section>

        <section class="section">
            <table class="table">
            <thead>
                <tr>
                    <th>SKU</th>
                    <th>Sub Condition</th>
                    <th>リードタイム</th>
                    <th>監視数</th>
                    <th style="width:180px;">販売価格</th>
                    <th>前回価格</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="sku of skus" :key="sku.sku_id">
                <tr>
                    <td>{{ sku.sku_code }}</td>
                    <td>{{ sku.sub_condition_display }}</td>
                    <td>{{ sku.leadtime }}</td>
                    <td>{{ sku.quantity }}</td>
                    <td>
                        <div class="input-group">
                            <input
                                type="number"
                                class="form-control no-spin"
                                v-model="sku.price"
                            >
                            <span class="input-group-text">円</span>
                        </div>
                    </td>
                    <td>{{ $helper.number(sku.original_price) }}</td>
                </tr>
                <tr>
                    <td colspan="5">{{ sku.condition_note }}</td>
                    <td colspan="2">
                        <button @click="updateSku(sku)" v-if="sku.quantity === 0" type="submit" class="btn btn-lg btn-info btn-full">再出品を行う</button>
                        <button @click="updateSku(sku)" v-else type="submit" class="btn btn-lg btn-info btn-full">追加出品を行う</button>
                    </td>
                </tr>
                </template>
                <template v-if="new_sku">
                <tr>
                    <td>{{ new_sku.sku_code }}</td>
                    <td>
                        <form-select
                            v-model="new_sku.sub_condition"
                            :options="SubCondition.options()"
                            :required="true"
                        ></form-select>
                    </td>
                    <td>
                        <div class="input-group">
                            <input v-model="new_sku.leadtime" type="number" class="form-control" required>
                            <span class="input-group-text">日</span>
                        </div>
                    </td>
                    <td>{{ new_sku.quantity }}</td>
                    <td>
                        <div class="input-group">
                            <input v-model="new_sku.price" type="number" class="form-control no-spin" required>
                            <span class="input-group-text">円</span>
                        </div>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="5">
                        <textarea v-model="new_sku.condition_note" class="form-control" required></textarea>
                        <div class="row mt-2">
                            <div class="col-md-12">
                            <select v-model="selected_template_note" @change="pasteTemplate" class="form-control">
                                <option :value="null">-- テンプレートを選択 --</option>
                                <option
                                    v-for="note of template_notes"
                                    :key="note.template_note_id"
                                    :value="note.template_note_id"
                                >{{ note.template_name }}</option>
                            </select>
                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <button @click="createSku(sku)" type="submit" class="btn btn-lg btn-primary btn-full">新規出品</button>
                    </td>
                </tr>
                </template>
            </tbody>
            </table>
        </section>
    </template>
</template>


<script>
import InlineLoader from '@/shared/components/tool/InlineLoader';
import FormSelect from '@/shared/components/form/FormSelect.vue';
import Sku from '@/shared/models/entities/sku';
import TemplateNote from '@/shared/models/entities/template-note';
import YahooAuctionInformation from '@/shared/models/entities/yahoo-auction-information';
import SubCondition from '@/shared/models/enums/sub-condition';

export default {
    name: 'PageEntry',
    components: {
        InlineLoader,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            asin: this.$route.query.asin,
            auction_url: this.$route.query.url,

            asin_info: null,
            asin_info_loading: true,
            asin_offers: [],
            asin_offers_loading: true,
            asin_offers_lowest: null,
            asin_fee: null,
            asin_fee_loading: true,
            auction_info: null,
            auction_info_loading: true,
            auction_history: [],
            auction_history_loading: true,
            skus: [],
            skus_loading: true,

            new_sku: new Sku(),

            template_notes: [],
            selected_template_note: null,

            keyword: null,

            calculator: {
                base: 'profit_ratio', // or 'profit_price'
                listing_price: 0, // 販売価格
                estimated_cost: 0, // 予想落札価格
                referral_fee: 0, // Amazon手数料
                estimated_shipping: this.$store.state.user.auth.account.default_shipping_fee ?? 0, // 送料
                other_fee: 0, // その他手数料
                profit_price: 0, // 利益額
                profit_ratio: this.$store.state.user.auth.account.default_profit_ratio ?? 20, // 利益率
            },

            prepare_form: {
                asin: this.$route.query.asin,
                auction_url: this.$route.query.url,
            },

            SubCondition,
        }
    },
    mounted() {
        if (this.asin && this.auction_url) {
            this.init();
        }
    },
    methods: {
        prepare() {
            this.asin = this.prepare_form.asin;
            this.auction_url = this.prepare_form.auction_url;
            this.init();
        },
        init() {
            this.getAsinInfo();
            this.getAsinOffers();
            this.getAsinFee();
            this.getSkus();
            this.prepareNewSku();
            this.getAuctionInfo();

            this.getSearchQuery(() => {
                this.getAuctionHistory();
            });

            this.getTemplateNotes();
        },
        getAsinInfo() {
            this.asin_info = null;
            this.asin_info_loading = true;

            this.$http.get(`/asins/${this.asin}`)
            .then(response => {
                this.asin_info = response.data;
            })
            .finally(() => {
                this.asin_info_loading = false;
            });
        },
        getAsinOffers() {
            this.asin_offers = [];
            this.asin_offers_loading = true;

            this.$http.get(`/asins/${this.asin}/offers`)
            .then(response => {
                for (let row of response.data) {
                    row.price = Math.floor(row.price); // たまに小数が返ってくるので整数化
                    this.asin_offers.push(row);
                    if (this.asin_offers_lowest === null || row.price < this.asin_offers_lowest) {
                        this.asin_offers_lowest = row.price;
                    }
                }
                this.calculate();
            })
            .finally(() => {
                this.asin_offers_loading = false;
            });
        },
        getAsinFee() {
            this.asin_fee = null;
            this.asin_fee_loading = true;

            this.$http.get(`/asins/${this.asin}/fee`)
            .then(response => {
                this.asin_fee = response.data;
                this.calculate();
            })
            .finally(() => {
                this.asin_fee_loading = false;
            });
        },
        getSkus() {
            this.skus = [];
            this.skus_loading = true;

            this.$http.get('/skus', {params: {asin: this.asin}})
            .then(response => {
                for (let row of response.data.data) {
                    this.skus.push(new Sku(row));
                }
                this.calculate();
            })
            .finally(() => {
                this.skus_loading = false;
            });
        },
        prepareNewSku() {
            this.$http.get('/skus/next', {params: {asin: this.asin}})
            .then(response => {
                this.new_sku.sku_code = response.data.next_sku;
                this.new_sku.quantity = 1;
                this.new_sku.leadtime = this.$store.state.user.auth.account.default_lead_time;
                this.new_sku.sub_condition = this.$store.state.user.auth.account.default_sub_condition ?? SubCondition.LIKE_NEW;
            })
            .finally(() => {
                this.skus_loading = false;
            });
        },
        getAuctionInfo() {
            this.auction_info = null;
            this.auction_info_loading = true;

            this.$http.get('/yahoo-auctions/auction', {params: {url: this.auction_url}})
            .then(response => {
                this.auction_info = new YahooAuctionInformation(response.data);
            })
            .finally(() => {
                this.auction_info_loading = false;
            });
        },
        getSearchQuery(next) {
            this.keyword = null;

            this.$http.get(`/search-queries/${this.asin}`)
            .then(response => {
                this.keyword = response.data.keyword;
                if (next) {
                    next();
                }
            })
            .finally(() => {
                this.asin_fee_loading = false;
            });
        },
        getAuctionHistory() {
            this.auction_history = [];
            this.auction_history_loading = true;

            this.$http.get('/yahoo-auctions/history', {params: {keyword: this.keyword}})
            .then(response => {
                let max = 0;

                for (let row of response.data) {
                    this.auction_history.push(row);
                    if (row.price) {
                        if (max === 0 || row.price > max) {
                            max = row.price;
                        }
                    }
                }

                this.calculator.estimated_cost = max;
                this.calculate();
            })
            .finally(() => {
                this.auction_history_loading = false;
            });
        },
        getTemplateNotes() {
            this.template_notes = [];

            this.$http.get('/template-notes')
            .then(response => {
                for (let row of response.data) {
                    let template_note = new TemplateNote(row)
                    this.template_notes.push(template_note);
                    if (template_note.is_default === 1) {
                        this.selected_template_note = template_note.template_note_id;
                        this.new_sku.condition_note = template_note.template_content;
                    }
                }
            });
        },
        calculate(base) {
            if (this.asin_fee === null) {
                return;
            }

            if (base) {
                this.calculator.base = base;
            }

            this.calculator.other_fee = Math.round(((this.asin_fee.per_item_fee ?? 0) + (this.asin_fee.variable_closing_fee ?? 0)) * 1.1)
            let referral_fee_rate = this.asin_fee.referral_fee / 1000;
            let cost = this.calculator.estimated_cost + this.calculator.estimated_shipping + this.calculator.other_fee;

            if (this.calculator.base == "profit_price") {
                //利益額をもとに販売価格計算
                this.calculator.listing_price = Math.round((cost + this.calculator.profit_price) / (1 - referral_fee_rate));
                this.calculator.referral_fee = Math.round(this.calculator.listing_price * referral_fee_rate * 1.1);
                this.calculator.profit_ratio = Math.round((this.calculator.profit_price / this.calculator.listing_price) * 1000) / 10;
            } else {
                //利益率をもとに販売価格計算
                this.calculator.listing_price = Math.round(cost / (1 - referral_fee_rate - (this.calculator.profit_ratio / 100)));
                this.calculator.referral_fee = Math.round(this.calculator.listing_price * referral_fee_rate * 1.1);
                this.calculator.profit_price = this.calculator.listing_price - this.calculator.referral_fee - cost;
            }

            //計算上の販売価格と、競合の最安値との、高い方を採用
            let best_price = this.calculator.listing_price;
            if (this.asin_offers_lowest && best_price < this.asin_offers_lowest) {
                best_price = this.asin_offers_lowest;
            }

            this.new_sku.price = best_price;
            for (let sku of this.skus) {
                sku.price = best_price;
            }
        },
        pasteTemplate() {
            if (!this.selected_template_note) {
                return;
            }

            let template_note = this.template_notes.find(row => row.template_note_id === this.selected_template_note);

            if (template_note) {
                this.new_sku.condition_note = template_note.template_content;
            }
        },
        // 新規出品
        createSku() {
            this.startScreenLoading();
            this.$http.post('/skus', {
                asin: this.asin,
                sub_condition: this.new_sku.sub_condition,
                leadtime: this.new_sku.leadtime,
                price: this.new_sku.price,
                condition_note: this.new_sku.condition_note,
                auction_url: this.auction_url,
                auction_title: this.auction_info.title,
                estimated_cost: this.calculator.estimated_cost,
                estimated_shipping: this.calculator.estimated_shipping,
            })
            .then(() => {
                this.$router.push({name: 'PageInventory'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // 再出品を行う or 追加出品を行う
        updateSku(sku) {
            this.startScreenLoading();
            this.$http.post(`/skus/${sku.sku_id}/auctions`, {
                price: sku.price,
                auction_url: this.auction_url,
                auction_title: this.auction_info.title,
                estimated_cost: this.calculator.estimated_cost,
                estimated_shipping: this.calculator.estimated_shipping,
            })
            .then(() => {
                this.$router.push({name: 'PageInventory'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    },
    computed: {
        history_url() {
            if (this.keyword) {
                let encoded = encodeURIComponent(this.keyword);
                return `https://auctions.yahoo.co.jp/closedsearch/closedsearch?n=100&ei=UTF-8&va=${encoded}&vo=&ve=&auccat=0&aucminprice=&aucmaxprice=&istatus=2&tab_ex=commerce&f_adv=1&slider=0`;
            }

            return null;
        },
    },
}
</script>

<style scoped>
    .no-spin::-webkit-inner-spin-button,
    .no-spin::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance:textfield;
    }
</style>
