import { isNud } from '@/shared/utilities/typing'
import User from './user';
import AsinCache from './asin-cache';
import Auction from './auction';
import SubCondition from '@/shared/models/enums/sub-condition';
import SkuSyncQueue from './sku-sync-queue';

/**
 * SKU エンティティ
 */
class Sku {
    sku_id;
    sku_code;
    asin;
    title;
    item_condition;
    sub_condition;
    condition_note;
    leadtime;
    price;
    quantity;
    asin_data_json;
    estimated_cost;
    estimated_shipping;
    is_draft;
    is_deleting;    // 削除中フラグ
    latest_sku_sync_queue_id;   // 最新のSKU Amazon同期トランID
    register_user;

    active_auctions;
    ended_auctions;
    latest_sku_sync_queue;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.sku_id = properties.sku_id;
        this.sku_code = properties.sku_code;
        this.asin = properties.asin;
        this.title = properties.title;
        this.item_condition = properties.item_condition;
        this.sub_condition = properties.sub_condition;
        this.condition_note = properties.condition_note;
        this.leadtime = properties.leadtime;
        this.price = properties.price;
        this.original_price = properties.price; //変更前
        this.quantity = properties.quantity;
        this.asin_data_json = properties.asin_data_json;
        this.estimated_cost = properties.estimated_cost;
        this.estimated_shipping = properties.estimated_shipping;
        this.is_draft = properties.is_draft;
        this.is_deleting = properties.is_deleting;

        this.register_user = new User(properties.register_user);
        this.asin_cache = new AsinCache(properties.asin_cache);

        this.latest_sku_sync_queue = null;
        if (properties.latest_sku_sync_queue) {
            this.latest_sku_sync_queue = new SkuSyncQueue(properties.latest_sku_sync_queue);
        }

        this.active_auctions = [];
        if (properties.active_auctions) {
            for (let auction of properties.active_auctions) {
                this.active_auctions.push(new Auction(auction));
            }
        }

        this.ended_auctions = [];
        if (properties.ended_auctions) {
            for (let auction of properties.ended_auctions) {
                this.ended_auctions.push(new Auction(auction));
            }
        }
    }

    get sub_condition_display() {
        return SubCondition.get(this.sub_condition);
    }

    get uneditable() {
        return (
            this.is_deleting === 1
        )
    }
}

export default Sku;
