import { isNud } from '@/shared/utilities/typing'
import SyncQueueType from '@/shared/models/enums/sync-queue-type';
import SyncQueueStatus from '@/shared/models/enums/sync-queue-status';

/**
 * SkuSyncQueue エンティティ
 */
class SkuSyncQueue {
    sku_sync_queue_id;
    sku_id;
    account_id;
    sku_history_id;
    sync_queue_type;
    sync_queue_status;
    action_datetime;
    batch_datetime;
    sync_datetime;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.sku_sync_queue_id = properties.sku_sync_queue_id;
        this.sku_id = properties.sku_id;
        this.account_id = properties.account_id;
        this.sku_history_id = properties.sku_history_id;
        this.sync_queue_type = properties.sync_queue_type;
        this.sync_queue_status = properties.sync_queue_status;
        this.action_datetime = properties.action_datetime;
        this.batch_datetime = properties.batch_datetime;
        this.sync_datetime = properties.sync_datetime;
    }

    get sync_queue_type_display() {
        return SyncQueueType.get(this.sync_queue_type);
    }

    get sync_queue_status_display() {
        return SyncQueueStatus.get(this.sync_queue_status);
    }
}

export default SkuSyncQueue;
